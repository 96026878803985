/** @jsx jsx */
import styled from "@emotion/styled";
import { Flex, jsx, Text, Heading } from "theme-ui";

import LinkButton from "./LinkButton";

// simple styled container, but gets props
const BlogCardContainer = styled.article`
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
`;

const BlogCardBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 25px;
`;

const BlogCard = ({
  blogCategory,
  blogCategoryColor = "primary",
  blogDate,
  blogExcerpt,
  blogImageURL,
  blogLinkURL,
  blogTitle,
  imageAltText = "Blog hero image",
  imageFound,
  linkText = "Read Now",
  ...props
}) => {
  const date = new Date(blogDate);
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();

  const formattedDate = `${month} ${year}`;

  return (
    <BlogCardContainer {...props}>
      <BlogCardBody>
        <Flex sx={{ alignItems: "center" }}>
          {blogDate === undefined ? (
            ""
          ) : (
            <Text as="p" sx={{ fontSize: [1, null, null, 1], mr: 4 }}>
              {formattedDate}
            </Text>
          )}

          {blogCategory === undefined ? (
            ""
          ) : (
            <Text
              as="span"
              sx={{
                backgroundColor: blogCategoryColor,
                borderRadius: 67,
                color: "lightGray",
                fontSize: [1, null, null, 1],
                px: 8,
                py: 1,
                whiteSpace: "nowrap",
              }}
            >
              {blogCategory}
            </Text>
          )}
        </Flex>

        <Heading
          as="h1"
          mt={3}
          sx={{ fontSize: [2, null, null, 3], lineHeight: "33px" }}
        >
          {blogTitle}
        </Heading>
        <Text
          className="title"
          as="p"
          mt={3}
          mb={7}
          sx={{ flex: 1, fontSize: [1] }}
        >
          {blogExcerpt}
        </Text>

        <LinkButton
          buttonText={linkText}
          linkTo={blogLinkURL}
          fontSize={2}
          px={10}
          py={2}
          variant="secondary"
          news={true}
        />
      </BlogCardBody>
    </BlogCardContainer>
  );
};

export default BlogCard;
